<template>
  <div>
    <el-dialog title="评价" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
      <div>
        <el-form
          ref="form"
          :model="form"
          label-width="180px"
          :rules="rules"
          :disabled="rowData.commentExists"
        >
          <div>对客服评价：</div>
          <el-form-item label="评价等级：" class="formItem" prop="waiterStar">
            <el-rate v-model="form.waiterStar"></el-rate>
          </el-form-item>
          <el-form-item label="评价内容: ">
            <el-input
              v-model="form.waiterContent"
              placeholder="请输入评价内容"
              type="textarea"
              class="input-width"
              :rows="5"
              clearable
            ></el-input>
          </el-form-item>
          <div>对老师的评价：</div>
          <el-form-item label="评价等级：" class="formItem" prop="teacherStar">
            <el-rate v-model="form.teacherStar"></el-rate>
          </el-form-item>
          <el-form-item label="评价内容: ">
            <el-input
              v-model="form.teacherContent"
              placeholder="请输入评价内容"
              type="textarea"
              class="input-width"
              :rows="5"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="!rowData.commentExists" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSubmit('form')">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import to from 'await-to'
import { searchComment, submitComment } from '@/api/teacher'
export default {
  data() {
    var validate = function(rule, value, callback) {
      if (!value || value.length == 0 || value == null || value == undefined) {
        if (rule.field == 'waiterStart') {
          return callback('请选择客服评价等级')
        } else {
          return callback('请选择老师评价等级')
        }
      }
      return callback()
    }
    return {
      dialogVisible: false,
      form: {},
      rules: {
        waiterStar: [{ required: true, validator: validate, trigger: 'blur' }],
        teacherStar: [{ required: true, validator: validate, trigger: 'blur' }],
      },
      rowData: {},
    }
  },
  created() {},
  methods: {
    show(row) {
      this.dialogVisible = true
      this.rowData = row
      if (row.commentExists) {
        this.searchCommentData(row)
      }
    },
    async searchCommentData(row) {
      const [res, err] = await to(
        searchComment({
          teacherCommentId: row.teacherCommentId,
          waiterCommentId: row.waiterCommentId,
        }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.form.teacherContent = res.data.teacherComment.teacherContent
      this.form.teacherStar = res.data.teacherComment.teacherStar
      this.form.waiterContent = res.data.waiterComment.waiterContent
      this.form.waiterStar = res.data.waiterComment.waiterStar
    },
    async submitCommentData() {
      let obj = {
        reserveTeacherId: this.rowData.id,
        teacherCommentCreateReqDto: {
          teacherContent: this.form.teacherContent,
          teacherId: this.rowData.teacherId,
          teacherStar: this.form.teacherStar,
        },
        waiterCommentCreateReqDto: {
          waiterContent: this.form.waiterContent,
          waiterId: this.rowData.waiterId,
          waiterStar: this.form.waiterStar,
        },
      }
      const [, err] = await to(submitComment(obj))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '评价成功' })
    },
    async handleSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.submitCommentData()
          this.dialogVisible = false
          this.$emit('success')
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.formItem {
  display: flex;
  align-items: center;
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
