<template>
  <div class="myAboutList">
    <el-dialog title="评价二维码" :visible.sync="codeFlag" width="20%">
      <div ref="foo">
        <img id="img_body" :src="codeImg" alt="" style="width:100%" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="download">保 存</el-button>
        <el-button type="primary" @click="copyImg()">复制二维码</el-button>
      </span>
    </el-dialog>
    <table-list
      :title="tableTitle"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="aboutList"
      :options="{ selection: true, operatesWidth: 300, fixScrollTable: true }"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>

    <Dialog
      :dialog-title="dialogTitle"
      :dialog-visible="dialogVisible"
      :data="fromData"
      :btn-text="btnText"
      @handleClose="handleClose"
      @editChange="editChange"
    ></Dialog>

    <evaluate ref="evaluate" @success="getMyAboutListData()"></evaluate>

    <dialogSetting ref="dialogSetting"></dialogSetting>
    <!-- <el-dialog
      title="培训信息"
      :visible.sync="dialogFormVisible"
      width="480px"
      class="Item-training"
    >
      <div class="dialogFee">
        <el-form ref="form" :model="form" :rules="rule">
          <el-form-item label="培训名称：" prop="trainingName">
            <el-input
              v-model="form.trainingName"
              placeholder="请输入培训名称"
              class="input-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="培训时间：" prop="trainingTime">
            <el-date-picker
              v-model="form.trainingTime"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="请选择开始日期"
              end-placeholder="请选择结束日期"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="培训地点：" prop="trainingAddress">
            <el-input
              v-model="form.trainingAddress"
              placeholder="请输入培训地点"
              class="input-width"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="HandleMakeSure">确 定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
//表格标题
const tableTitle = '我的预约列表'
// 搜索框配置
const searchForm = _this => [
  {
    label: '讲师姓名',
    prop: 'teacherName',
  },
  {
    label: '预约类型',
    type: 'select',
    prop: 'reserveType',
    children: _this.typeList.map(item => ({ value: item.key, label: item.value })),
  },
  {
    label: '预约状态',
    type: 'select',
    prop: 'reserveStatus',
    children: _this.statusList.map(item => ({ value: item.key, label: item.value })),
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'reserveType',
    label: '预约类型',
    minWidth: 100,
    formatter: row => {
      let type = ''
      if (row.reserveType == 1) {
        type = '线下授课'
      } else if (row.reserveType == 2) {
        type = '专题讲座'
      } else if (row.reserveType == 3) {
        type = '定制课程'
      } else {
        type = '其他'
      }
      return type
    },
  },
  {
    prop: 'teacherName',
    minWidth: 80,
    label: '预约讲师',
  },
  {
    prop: 'studentCount',
    minWidth: 80,
    label: '预约人数',
    align: 'right',
  },
  {
    prop: 'costBudget',
    minWidth: 160,
    align: 'right',
    label: '预估费用',
    formatter: row => {
      let money = ''
      if (row.costBudget == 1) {
        money = '￥5000.00-￥10000.00'
      } else if (row.costBudget == 2) {
        money = '￥10000.00-￥15000.00'
      } else if (row.costBudget == 3) {
        money = '￥15000.00-￥20000.00'
      } else if (row.costBudget == 4) {
        money = '￥20000.00-￥30000.00'
      } else if (row.costBudget == 5) {
        money = '￥30000.00-￥40000.00'
      } else {
        money = '￥50000.00-￥100000.00'
      }
      return money
    },
  },
  {
    prop: 'reserveTime',
    label: '预约时间',
    minWidth: '180',
  },

  {
    prop: 'contactName',
    minWidth: '120',
    label: '联系人',
  },
  {
    prop: 'contactPhone',
    minWidth: '120',
    label: '电话',
  },
  {
    prop: 'trainingName',
    minWidth: '200',
    label: '培训名称',
  },
  {
    prop: 'reserveStatus',
    label: '预约状态',
    minWidth: '180',
    formatter: row => {
      let status = ''
      if (row.reserveStatus == 0) {
        status = '取消预约'
      } else if (row.reserveStatus == 1) {
        status = '待客服确认'
      } else if (row.reserveStatus == 2) {
        status = '客服联系讲师中'
      } else if (row.reserveStatus == 3) {
        status = '确认合同'
      } else if (row.reserveStatus == 4) {
        status = '授课培训'
      } else if (row.reserveStatus == 5) {
        status = '培训已完成'
      } else if (row.reserveStatus == 6) {
        status = '待客服确认完成'
      } else {
        status = '已完成'
      }
      return status
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
    isShow: row => {
      return row.reserveStatus == 1 || row.reserveStatus == 2 || row.reserveStatus == 3
        ? true
        : false
    },
  },
  {
    label: '培训信息设置',
    method: _this.handleInfoSetting,
    isShow: row => {
      return row.reserveStatus == 1 ||
        row.reserveStatus == 2 ||
        row.reserveStatus == 3 ||
        row.reserveStatus == 4
        ? true
        : false
    },
  },
  {
    label: '查看详情',
    method: _this.handleDetail,
  },
  {
    label: '场次/签到设置',
    method: _this.handleCheck,
    isShow: row => {
      return row.reserveStatus == 4 ||
        row.reserveStatus == 5 ||
        row.reserveStatus == 6 ||
        row.reserveStatus == 7
        ? true
        : false
    },
  },
  {
    label: '评价二维码',
    method: _this.handleCode,
    isShow: row => {
      return row.reserveStatus == 4 ||
        row.reserveStatus == 5 ||
        row.reserveStatus == 6 ||
        row.reserveStatus == 7
        ? true
        : false
    },
  },
  {
    label: '宣传海报',
    method: _this.handlePoster,
    isShow: row => {
      return row.reserveStatus == 4 ||
        row.reserveStatus == 5 ||
        row.reserveStatus == 6 ||
        row.reserveStatus == 7
        ? true
        : false
    },
  },
  {
    label: '报名/签到统计',
    method: _this.handleTj,
    isShow: row => {
      return row.reserveStatus == 4 ||
        row.reserveStatus == 5 ||
        row.reserveStatus == 6 ||
        row.reserveStatus == 7
        ? true
        : false
    },
  },
  {
    label: '取消预约',
    method: _this.handleDelete,
    color: '#979797',
    isShow: row => {
      return row.reserveStatus == 1 || row.reserveStatus == 2 || row.reserveStatus == 3
        ? true
        : false
    },
  },
  // {
  //   label: '去评价',
  //   method: _this.handleEvaluate,
  //   isShow: row => {
  //     return row.reserveStatus == 7 && !row.commentExists ? true : false
  //   },
  // },
  // {
  //   label: '查看评价',
  //   method: _this.handleEvaluate,
  //   isShow: row => {
  //     return row.reserveStatus == 7 && row.commentExists ? true : false
  //   },
  // },
]
//预约类型数据
const typeList = [
  { key: 1, value: '线下授课' },
  { key: 2, value: '专题讲座' },
  { key: 3, value: '定制课程' },
  { key: 4, value: '其他' },
]
//预约状态数据
const statusList = [
  { key: 0, value: '取消预约' },
  { key: 1, value: '待客服确认' },
  { key: 2, value: '客服联系讲师中' },
  { key: 3, value: '确认合同' },
  { key: 4, value: '授课培训' },
  { key: 5, value: '培训已完成' },
  { key: 6, value: '待客服确认完成' },
  { key: 7, value: '已完成' },
]
import Dialog from '../components/dialog'
import TableList from '@/components/TableList'
import { validateInputMobile } from '@/utils/validate'
import to from 'await-to'
import { getMyAboutList, getMySearchAbout, getDelAbout, reserveCommentQrcord } from '@/api/teacher'
import evaluate from '../components/evaluate'
import dialogSetting from '../components/dialogSetting'
export default {
  components: {
    TableList,
    Dialog,
    evaluate,
    dialogSetting,
  },
  data() {
    return {
      btnText: '确认保存',
      fromData: {},
      dialogVisible: false,
      loading: false,
      bannerType: [],
      aboutList: [],
      tableTitle,
      searchForm,
      operates,
      columns,
      typeList,
      statusList,
      pager: {
        total: 0,
        size: 10,
        current: 1,
        teacherName: '',
        reserveType: '',
        reserveStatus: '',
      },
      dialogTitle: '编辑信息',
      formDetail: {
        reserveType: '',
        reserveTime: '',
        studentCount: '',
        costBudget: '',
        contactName: '',
        contactPhone: '',
        annexUrlList: [],
        teacherId: '',
      },
      paramsFileList: [],
      fileList: [],
      selectList: [],
      rules: {
        reserveType: [{ required: true, message: '请选择预约类型', trigger: 'change' }],
        studentCount: [{ required: true, message: '请输入预约人数', trigger: 'blur' }],
        costBudget: [{ required: true, message: '请选择预估费用', trigger: 'change' }],
        contactName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        reserveTime: [{ required: true, message: '请选择预约时间', trigger: 'change' }],
        teacherId: [{ required: true, message: '请选择讲师', trigger: 'change' }],
        contactPhone: [{ required: true, validator: validateInputMobile, trigger: 'blur' }],
      },
      codeImg: '',
      codeFlag: false,
      dialogFormVisible: false,
      // form: {
      //   trainingName: '',
      //   trainingTime: [],
      //   trainingAddress: '',
      // },
      // rule: {
      //   trainingName: [
      //     { required: true, message: '请输入6-24字培训名称', trigger: 'blur' },
      //     { min: 6, max: 24, message: '请输入6-24字培训名称', trigger: 'blur' },
      //   ],
      //   trainingTime: [{ required: true, message: '请选择培训时间', trigger: 'change' }],
      //   trainingAddress: [
      //     { required: true, message: '请输入6-24字培训地址', trigger: 'blur' },
      //     { min: 6, max: 24, message: '请输入6-24字培训地址', trigger: 'blur' },
      //   ],
      // },
    }
  },
  created() {
    this.getMyAboutListData()
  },
  methods: {
    async getMyAboutListData() {
      this.loading = true
      const [res, err] = await to(getMyAboutList(this.pager))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.aboutList = res.data.list
      this.pager.total = res.data.total
    },
    async getMySearchAboutData(id) {
      const [res, err] = await to(getMySearchAbout({ id: id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.fromData = res.data
    },
    // 查询按钮
    onSearch(params) {
      this.pager.current = 1
      this.pager.teacherName = params.teacherName
      this.pager.reserveType = params.reserveType
      this.pager.reserveStatus = params.reserveStatus
      this.getMyAboutListData()
    },
    handleEdit(row) {
      this.getMySearchAboutData(row.id)
      this.dialogVisible = true
    },
    handleInfoSetting(row) {
      this.$refs.dialogSetting.show(row.id)
    },
    handleEvaluate(row) {
      this.$refs.evaluate.show(row)
    },
    handleClose() {
      this.dialogVisible = false
    },
    editChange() {
      this.getMyAboutListData()
    },
    handleDetail(row) {
      this.$router.push({
        path: '/teacher/myAboutDetail',
        query: { id: row.id, organizationId: row.organizationId, teacherId: row.teacherId },
      })
    },
    handleCheck(row) {
      this.$router.push({
        path: '/teacher/myCheck',
        query: { teacherId: row.id },
      })
    },
    handleTj(row) {
      this.$router.push({
        path: '/teacher/recordInfo',
        query: { id: row.id },
      })
    },
    async handleCode(row) {
      const [res, err] = await to(reserveCommentQrcord({ reserveTeacherId: row.id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.codeImg = res.data.wxMiniQRCodeLink
      this.codeFlag = true
    },
    handlePoster({ id }) {
      this.$router.push(`/teacher/poster?reserveId=${id}`)
    },
    //复制二维码
    async copyImg() {
      const img = document.querySelector('#img_body')
      const setToClipboard = blob => {
        // eslint-disable-next-line no-undef
        const data = [new ClipboardItem({ [blob.type]: blob })]
        return navigator.clipboard.write(data)
      }
      try {
        const response = await fetch(img.src)
        const blob = await response.blob()
        console.log(blob)
        setToClipboard(blob)
        this.$message({
          message: '复制成功',
          type: 'success',
        })
      } catch (error) {
        this.$message({
          message: '复制失败',
          type: 'error',
        })
      }
    },

    //下载图片
    download() {
      let imgData = this.codeImg //填写你的base64
      this.downloadFile('二维码.png', imgData)
      this.codeFlag = false
    },
    //下载
    downloadFile(fileName, content) {
      let aLink = document.createElement('a')
      let blob = this.base64ToBlob(content) //new Blob([content]);

      let evt = document.createEvent('HTMLEvents')
      evt.initEvent('click', true, true) //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName
      aLink.href = URL.createObjectURL(blob)

      // aLink.dispatchEvent(evt);
      aLink.click()
    },
    //base64转blob
    base64ToBlob(code) {
      let parts = code.split(';base64,')
      let contentType = parts[0].split(':')[1]
      let raw = window.atob(parts[1])
      let rawLength = raw.length

      let uInt8Array = new Uint8Array(rawLength)

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      return new Blob([uInt8Array], { type: contentType })
    },
    async handleDelete(row) {
      const [, err] = await to(getDelAbout({ id: row.id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '取消成功' })
      this.getMyAboutListData()
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getMyAboutListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getMyAboutListData()
    },
  },
}
</script>

<style scoped lang="scss">
.myAboutList {
  ::v-deep.operate-group {
    text-align: left;
    > span {
      margin-right: 20px !important;
    }
  }
  .el-upload__tip {
    margin-left: 10px;
  }
  .input-width {
    width: 235px !important;
  }
  // .Item-training ::v-deep .el-dialog__header {
  //   border-bottom: 1px solid #d6d6d6;
  // }
  // .Item-training ::v-deep .el-dialog__body {
  //   padding: 25px 0px 10px 25px;
  //   .el-form-item__label {
  //     text-align: left;
  //     color: #333333;
  //     font-size: 14px;
  //     font-family: Source Han Sans CN;
  //     font-weight: 400;
  //   }
  //   .el-date-editor--daterange.el-input,
  //   .el-date-editor--daterange.el-input__inner,
  //   .el-date-editor--timerange.el-input,
  //   .el-date-editor--timerange.el-input__inner {
  //     width: 300px;
  //   }
  //   .input-width {
  //     width: 240px !important;
  //   }
  // }
  // .Item-training ::v-deep .el-dialog__footer {
  //   border-top: 1px solid #c7c7c7;
  //   text-align: right;
  //   padding-top: 20px;
  // }
}
</style>
