<template>
  <div class="Setting">
    <el-dialog
      title="培训信息"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="480px"
      class="Item-training"
      @close="cancel"
    >
      <div class="dialogFee">
        <el-form ref="form" :model="form" :rules="rule">
          <el-form-item label="培训名称：" prop="trainingName">
            <el-input
              v-model="form.trainingName"
              placeholder="请输入培训名称"
              class="input-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="培训时间：" prop="trainingTime">
            <el-date-picker
              v-model="form.trainingTime"
              type="daterange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="-"
              start-placeholder="请选择开始日期"
              end-placeholder="请选择结束日期"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="培训地点：" prop="trainingAddress">
            <el-input
              v-model="form.trainingAddress"
              placeholder="请输入培训地点"
              class="input-width"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="HandleMakeSure">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import to from 'await-to'
import { editTrainInfo, getTrainInfo } from '@/api/teacher'
export default {
  name: 'DialogSetting',
  data() {
    return {
      dialogFormVisible: false,
      form: {
        trainingName: '',
        trainingTime: [],
        trainingAddress: '',
        trainingBeginTime: '',
        trainingEndTime: '',
        id: 0,
      },
      rule: {
        trainingName: [
          { required: true, message: '请输入6-24字培训名称', trigger: 'blur' },
          { min: 6, max: 24, message: '请输入6-24字培训名称', trigger: 'blur' },
        ],
        trainingTime: [{ required: true, message: '请选择培训时间', trigger: 'change' }],
        trainingAddress: [
          { required: true, message: '请输入6-24字培训地址', trigger: 'blur' },
          { min: 6, max: 24, message: '请输入6-24字培训地址', trigger: 'blur' },
        ],
      },
      id: 0,
    }
  },
  methods: {
    show(id) {
      this.dialogFormVisible = true
      this.form.id = id
      this.HandleGetInfo(id)
      this.$nextTick(() => {
        this.$refs['form'].resetFields()
      })
    },
    async HandleGetInfo(id) {
      const [res, err] = await to(getTrainInfo({ reserveTeacherId: id }))
      if (err) return this.$message.warning(err.msg)
      if (res.data.trainingName) {
        this.form.trainingName = res.data.trainingName
      }
      if (res.data.trainingAddress) {
        this.form.trainingAddress = res.data.trainingAddress
      }
      if (res.data.trainingBeginTime != null) {
        this.form.trainingTime.push(res.data.trainingBeginTime)
      }
      if (res.data.trainingEndTime != null) {
        this.form.trainingTime.push(res.data.trainingEndTime)
      }
    },
    HandleMakeSure() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.form.trainingBeginTime = this.form.trainingTime[0]
          this.form.trainingEndTime = this.form.trainingTime[1]
          const [, err] = await to(editTrainInfo(this.form))
          if (err) return this.$message.warning(err.msg)
          this.$message.success('培训信息设置成功')
          this.dialogFormVisible = false
          window.location.reload()
        }
      })
    },
    cancel() {
      let obj = {
        trainingName: '',
        trainingTime: [],
        trainingAddress: '',
        trainingBeginTime: '',
        trainingEndTime: '',
        id: 0,
      }
      this.dialogFormVisible = false
      this.form = obj
    },
  },
}
</script>
<style scoped lang="scss">
.Setting {
  .Item-training ::v-deep .el-dialog__header {
    border-bottom: 1px solid #d6d6d6;
  }
  .Item-training ::v-deep .el-dialog__body {
    padding: 25px 0px 10px 25px;
    .el-form-item__label {
      text-align: left;
      color: #333333;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
    }
    .el-date-editor--daterange.el-input,
    .el-date-editor--daterange.el-input__inner,
    .el-date-editor--timerange.el-input,
    .el-date-editor--timerange.el-input__inner {
      width: 300px;
    }
  }
  .input-width {
    width: 240px !important;
  }
  .Item-training ::v-deep .el-dialog__footer {
    border-top: 1px solid #c7c7c7;
    padding-top: 20px;
    text-align: right !important;
  }
}
</style>
